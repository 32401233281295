import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import PhotoAlbum from "react-photo-album";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";

const MediaMentionsPage = (props) => {

  const data = useStaticQuery(graphql`
    query MediaMentionsPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "media-mentions"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
          articleImages
        }
      }
    }
  `);
  const articles = data.markdownRemark.frontmatter.articleImages || [];
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  const [photos, setPhotos] = React.useState([]);

  React.useEffect(() => {
    articles.forEach((imgUrl, index) => {
      const img = new Image();
      img.onload = () => {
        setPhotos((prevPhotos) => [
          ...prevPhotos,
          {src: imgUrl, width: img.width, height: img.height}
        ]);
      };
      img.src = imgUrl;
    });
  }, [articles]);

  return (
    <Layout activeLink="/stay-informed" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>
      <div className="m-10">
        <PhotoAlbum layout="masonry" columns={3} photos={photos} onClick={({index}) => window.open(photos[index].src)}/>
      </div>
      <HelpMethodsSection/>
    </Layout>
  );
};

MediaMentionsPage.propTypes = {};

export default MediaMentionsPage;
